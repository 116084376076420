<template>
  <div class="page-main-box" id="serviceContractBox">
    <Button
      v-if="$store.getters.roles.includes('AddContract')"
      class="px-24 ml-24 mt-24 fz-12"
      type="primary"
      @click="handleAddContract"
      >新建合同模板</Button
    >
    <!-- 有数据 -->
    <div class="pt-24 pl-16 pr-16">
      <!-- 列表 -->
      <div :style="{ height: tableHeight + 'px' }">
        <p-table
          id="service-order-table"
          :dataSource="tableDataSource"
          height="auto"
        >
          <p-t-column type="seq" width="60" title="序号" fixed="left" />
          <p-t-column field="name" min-width="311" title="合同名称">
            <template v-slot="{ row }">
              <span class="contract-name" @click="handleToDetail(row)"
                >《{{
                  row.name?.length > 22
                    ? row.name?.substr(0, 22) + "..."
                    : row.name
                }}》</span
              >
            </template>
          </p-t-column>
          <p-t-column field="create_time" min-width="176" title="上传时间">
            <template v-slot="{ row }">
              <span>{{
                formatToDateTime(row.create_time, "yyyy-MM-dd hh:mm:ss")
              }}</span>
            </template>
          </p-t-column>
          <p-t-column field="creator" min-width="149" title="创建人" />
          <p-t-column field="effective_time" min-width="176" title="生效时间">
            <template v-slot="{ row }">
              <span>{{
                formatToDateTime(row.effective_time, "yyyy-MM-dd hh:mm:ss")
              }}</span>
            </template>
          </p-t-column>
          <p-t-column width="170" title="操作" fixed="right">
            <template v-slot="{ row }">
              <Button
                :disabled="row.effective_time > +new Date()"
                type="link"
                @click="handleDetail(row)"
              >
                签署详情
              </Button>
              <Button
                v-if="$store.getters.roles.includes('ServiceContractDelete')"
                :disabled="row.effective_time < +new Date()"
                type="link"
                @click="handleDelete(row)"
              >
                删除
              </Button>
              <Button
                v-if="$store.getters.roles.includes('ServiceContractAmend')"
                :disabled="row.effective_time < +new Date()"
                type="link"
                @click="handleAmend(row)"
              >
                修改
              </Button>
            </template>
          </p-t-column>
        </p-table>
      </div>
    </div>

    <!-- 删除确认框 -->
    <xModal
      :w="288"
      :visible="deleteModal"
      :closable="false"
      @handleOk="handleOk"
      @handleCancel="deleteModal = false"
    >
      <template #content>
        <p class="text-center">确定要删除此合同？</p>
      </template>
    </xModal>

    <!-- 合同详情弹窗 -->
    <xModal
      :w="781"
      title="查看合同"
      :visible="detailModal"
      :closable="false"
      :footer="null"
      @handleCancel="detailModal = false"
    >
      <template #content>
        <p class="content-detail" v-html="contentDetail"></p>
      </template>
    </xModal>

    <!-- 修改记录页面 -->
    <Drawer
      class="drawer-box"
      @close="signDetailModal = false"
      v-model:visible="signDetailModal"
      placement="bottom"
      height="560"
    >
      <SignDetail
        v-if="signDetailModal"
        :childRow="childRow"
        @handleClose="signDetailModal = false"
      ></SignDetail>
    </Drawer>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { Button, message } from "ant-design-vue";
import { Table } from "@wlhy-web-components/common";
import {
  useContractTemplatePageApi,
  useContractTemplateDeleteApi,
  useContractDetailApi
} from "@/apis/contract";
import $await from "@/utils/await";
import useTableDataRecords from "@/hooks/useTableDataRecords";
import { formatToDateTime } from "@wlhy-web-lib/shared";
import useTableHeight from "@/hooks/useTableHeight";
import xModal from "@/components/xModal";
import Drawer from "@/components/Drawer";
import SignDetail from "./components/SignDetail/index";

export default {
  name: "service-contract",
  components: { Button, xModal, Drawer, SignDetail },
  setup() {
    const router = useRouter();
    const contractTemplatePageApi = useContractTemplatePageApi();
    const contractTemplateDeleteApi = useContractTemplateDeleteApi();
    const contractDetailApi = useContractDetailApi();

    // table高度
    const tableHeight = ref();

    const detailModal = ref(false);
    const signDetailModal = ref(false);
    const deleteModal = ref(false);
    const id = ref("");
    const childRow = ref();
    const contentDetail = ref("");

    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      },
      sum: {
        data: [],
        loading: false,
        error: ""
      }
    });

    const reflesh = Table.useTableConfig({
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const [, res] = await $await(contractTemplatePageApi(data));
        return useTableDataRecords(res);
      }
    });

    // 跳转到详情页
    const handleToDetail = async row => {
      const [, res] = await $await(contractDetailApi({ id: row.id }));
      if (res) {
        contentDetail.value = res.content;
        detailModal.value = true;
      }
    };

    // 删除合同
    const handleDelete = row => {
      id.value = row.id;
      deleteModal.value = true;
    };
    // 确定删除
    const handleOk = async () => {
      const [, res] = await $await(contractTemplateDeleteApi({ id: id.value }));
      if (res) {
        reflesh();
        message.success("删除成功！");
        deleteModal.value = false;
      }
    };

    // 修改合同
    const handleAmend = row => {
      router.push({
        path: "/contract/add-contract",
        query: { id: row.id }
      });
    };

    // 新建合同
    const handleAddContract = () => {
      router.push({ path: "/contract/add-contract" });
    };

    // 查看签署详情
    const handleDetail = row => {
      childRow.value = row;
      signDetailModal.value = true;
    };

    onMounted(() => {
      tableHeight.value = useTableHeight();
    });

    return {
      tableHeight,
      id,
      childRow,
      detailModal,
      deleteModal,
      signDetailModal,
      tableDataSource,
      formatToDateTime,
      contentDetail,
      handleToDetail,
      handleDelete,
      handleOk,
      handleAmend,
      handleAddContract,
      handleDetail
    };
  }
};
</script>

<style lang="less" scoped>
#serviceContractBox {
  position: relative;
  overflow: hidden;
  .contract-name {
    color: #007aff;
    cursor: pointer;
  }
}
.content-detail {
  display: flow-root;
}
</style>
