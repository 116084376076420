import { buildApi } from "@/utils/ajax";

const basicApi = buildApi("/portal/ops");

// 合同列表
export const useContractTemplatePageApi = basicApi({
  url: "/contract/template/page",
  withCredentials: true
});
// 删除合同
export const useContractTemplateDeleteApi = basicApi({
  url: "/contract/template/delete",
  withCredentials: true
});
// 新建合同
export const useContractTemplateCreateApi = basicApi({
  url: "/contract/template/create",
  withCredentials: true
});
// 编辑合同
export const useContractTemplateUpdateApi = basicApi({
  url: "/contract/template/update",
  withCredentials: true
});
// 合同详情
export const useContractDetailApi = basicApi({
  url: "/contract/template/detail",
  withCredentials: true
});
// 签署详情
export const useContractSignPageApi = basicApi({
  url: "/contract/sign/page",
  withCredentials: true
});
