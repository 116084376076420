<template>
  <div>
    <div class="d-flex">
      <p class="fw-600">合同名称： {{ childRow.name }}</p>
    </div>

    <p-table class="mt-16" :dataSource="tableDataSource">
      <p-t-column type="seq" width="60" title="序号" fixed="left" />
      <p-t-column field="company_name" min-width="336" title="企业名称">
        <template v-slot="{ row }">
          <span>{{
            row.company_name?.length > 18
              ? row.company_name?.substr(0, 18) + "..."
              : row.company_name
          }}</span>
        </template>
      </p-t-column>
      <p-t-column field="signatory" min-width="222" title="签署人" />
      <p-t-column field="sign_time" min-width="172" title="签署时间">
        <template v-slot="{ row }">
          <span>{{
            formatToDateTime(row.sign_time, "yyyy-MM-dd hh:mm:ss")
          }}</span>
        </template>
      </p-t-column>
      <p-t-column width="60" title="操作" fixed="right">
        <template v-slot="{ row }">
          <a-button type="link" @click="handleDetail(row)">
            查看
          </a-button>
        </template>
      </p-t-column>
    </p-table>
  </div>
</template>

<script>
import { reactive } from "vue";
import { Table } from "@wlhy-web-components/common";
import { useContractSignPageApi } from "@/apis/contract";
import $await from "@/utils/await";
import useTableDataRecords from "@/hooks/useTableDataRecords";
import { formatToDateTime } from "@wlhy-web-lib/shared";

export default {
  name: "service-configuration",
  components: {},
  props: {
    childRow: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const contractSignPageApi = useContractSignPageApi();

    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      }
    });

    Table.useTableConfig({
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          template_id: props.childRow.id,
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const [, res] = await $await(contractSignPageApi(data));
        return useTableDataRecords(res);
      }
    });

    // 查看
    const handleDetail = row => {
      window.open(row.ext_json.pdf_url);
    };

    return {
      tableDataSource,
      handleDetail,
      formatToDateTime
    };
  }
};
</script>
